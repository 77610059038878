.cloud-tag-card-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin: 20px 0
}

.cloud-tag-card-wrapper .title {
    font-size: 18px;
    color: #282828;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    position: relative;
    margin: 0px 20px 20px 20px;
}

.cloud-tag-card-wrapper .title:before {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 14px
}

.cloud-tag-card-wrapper .title:after {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
    bottom: 14px
}

.cloud-tag-card-wrapper .cloud-tag {

}

.cloud-tag-card-wrapper .cloud-tag ul {
    padding-left: 0px;
    list-style-type: none;
    overflow: hidden;
    font-size: 14px;
}

.cloud-tag-card-wrapper .cloud-tag ul li {
    line-height: 24px;
    height: 30px;
    display: block;
    background: #999;
    float: left;
    padding: 3px 11px;
    margin: 10px 10px 0 0;
    border-radius: 8px;
    -moz-transition: all .5s ease;
    -webkit-transition: all .5s ease;
    -ms-transition: all .5s ease;
    -o-transition: all .5s ease;
    transition: all .5s ease;
    transition: all 0.5s;
    color: #FFF
}
.cloud-tag-card-wrapper .cloud-tag ul li a{
    color: white;
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-7) {
    background: #8A9B0F
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-6) {
    background: #EB6841
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-5) {
    background: #3FB8AF
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-4) {
    background: #FE4365
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-3) {
    background: #FC9D9A
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-2) {
    background: #EDC951
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n-1) {
    background: #C8C8A9
}

.cloud-tag-card-wrapper .cloud-tag ul li:nth-child(8n) {
    background: #83AF9B
}

.cloud-tag-card-wrapper .cloud-tag ul li:first-child {
    background: #036564
}

.cloud-tag-card-wrapper .cloud-tag ul li:last-child {
    background: #3299BB
}

.cloud-tag-card-wrapper .cloud-tag ul li:hover {
    border-radius: 0;
    text-shadow: #000 1px 1px 1px
}


