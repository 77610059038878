.search-card-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.search-card-wrapper .search button {
    background: #555;
    border-color: #555
}

.search-card-wrapper .search button:focus {
    background: #555;
    border-color: #555
}

.search-card-wrapper .search button:hover {
    color: white;
    background: #333333;
    border-color: #555
}
