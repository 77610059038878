.user-card-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.user-card-wrapper .title {
    font-size: 18px;
    color: #282828;
    font-weight: 600;
    text-align: center;
    position: relative;
    margin: 0px 20px 20px 20px;
}


.user-card-wrapper .title:before {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 14px
}

.user-card-wrapper .title:after {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
    bottom: 14px
}

.user-card-wrapper .avatar {
    width: 100px;
    margin: auto;
}

.user-card-wrapper img {
    width: 100px;
    height: 100px;
    transition: all 4s;
    -moz-animation: all 4s;
    -o-animation: all 4s;
    -webkit-animation: all 4s;
}

.user-card-wrapper img:hover {
    transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
}

.user-card-wrapper .content {
    line-height: 30px;
    padding: 20px 30px 0 20px;
}

.user-card-wrapper .content p {
    background: #f6f6f6;
    margin: 8px 0;
    padding-left: 10px;
    border-radius: 5px;
    text-shadow: rgba(255, 255, 255, 0.3) 0px 1px 0px;
}
