.loading {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 0 40px;
    height: 80px;
    line-height: 80px;
    background: rgba(0, 0, 0, 0.75);
    border-radius: 6px;
    text-align: center;
    z-index: 9999;
    font-size: 16px;
    color: #fff;
}

.loading img {
    width: 32px;
    vertical-align: middle;
}

.loading span {
    margin-left: 12px;
}
