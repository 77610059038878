.header-wrapper {
    background: #333;
    color: #fff;
    box-sizing: border-box;
    line-height: 64px;
    z-index: 9;
}

.header-main {
    margin: 0 auto;
    max-width: 1280px;
}

.header-wrapper .header-main .logo-box {
    padding: 0 50px;
    display: inline-block;
    font-size: 26px;
    vertical-align: middle;
}
.header-wrapper .header-main .logo-box a {
    color: #fff;
}

.header-wrapper .header-main .menu-box {
    padding: 0 20px;
    display: inline-block;
    vertical-align: middle;
    margin-top: -1px;
    font-size: 17px;
}

.header-wrapper .header-main .menu-box .menu {

}

.header-wrapper .header-main .menu-box .menu  a{
    color: #fff;
}

.header-wrapper .header-main .menu-box .menu > li {
    margin-right: 20px;
    list-style-type: none;
    text-align: center;
    float: left;
}

.header-wrapper .header-main .menu-box .menu > li + li {
    margin: 0 20px;
}

.header-wrapper .header-main .menu-box .menu > li:nth-last-child(1) {
    margin-right: 0;
}

.header-wrapper .header-main .user-tool{
    display: inline-block;
}
