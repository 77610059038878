.wx-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.wx-wrapper .title {
    font-size: 18px;
    color: #282828;
    font-weight: 600;
    text-align: center;
    position: relative;
    margin: 0px 20px 20px 20px;
}

.wx-wrapper .title:before {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    left: 0;
    bottom: 14px
}

.wx-wrapper .title:after {
    content: "";
    width: 32%;
    height: 2px;
    background: #000;
    position: absolute;
    right: 0;
    bottom: 14px
}

.wx-wrapper .content {
    width: 90%;
    margin: 0 auto;
}
