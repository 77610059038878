.article-item-skeleton-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin: 20px 0;
    padding: 20px;
    mix-height: 210px;
    display: flex;
}

.article-item-skeleton-wrapper:nth-child(1) {
    margin-top: 0px
}

.article-item-skeleton-wrapper .image {
    flex: 1;
    display: inline-block;
    width: 210px;
    height: 210px;
    margin-right: 30px;
    overflow: hidden;
    border-radius: 10px;
}

.article-item-skeleton-wrapper .content {
    flex: 2;
    display: inline-block;
    width: 100%;
}
