.article-item-wrapper {
    border-radius: 15px;
    box-shadow: 0 0 8px rgba(0, 0, 0, .5);
    background: #FFF;
    border-right: 10px;
    overflow: hidden;
    margin: 20px 0;
    padding: 20px;
    mix-height: 210px;
    display: flex;
}

.article-item-wrapper:nth-child(1) {
    margin-top: 0px
}

.article-item-wrapper .image {
    flex: 1;
    display: inline-block;
    width: 210px;
    height: 210px;
    margin-right: 30px;
    overflow: hidden;
}

.article-item-wrapper .image img {
    border-radius: 10px;
}

.article-item-wrapper .content {
    flex: 2;
    display: inline-block;
    width: 100%;
}

.article-item-wrapper .content .title {
    overflow: hidden;
    max-height: none;
    font-weight: 600;
    white-space: pre-wrap;
}

.article-item-wrapper .content .title a {
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.article-item-wrapper .content .meta {
    color: #584c4c;
    margin: 4px 0;
    font-size: 15px;
    font-weight: 600;
}
.article-item-wrapper .content .meta > span {
    margin-right: 15px;
}

.article-item-wrapper .content .desc {
    margin: 5px 0 9px;
    overflow: hidden;
    font-weight: 600;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
}
